import Swiper, {
    Autoplay,
    EffectFade,
    FreeMode,
    HashNavigation,
    Keyboard,
    Lazy,
    Manipulation,
    Mousewheel,
    Navigation,
    Pagination,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';


export const Carousel = () => {

    const slider = document.querySelector(".swiper-container");
    if (slider) {
        const swiperArticle = new Swiper('.swiper-container', {
            modules: [Navigation, Manipulation, EffectFade, Mousewheel, Autoplay, Keyboard, Pagination],
            cssMode: false,
            direction: 'horizontal',
            followFinger: false,
            preloadImages: false,
            resizeObserver: true,
            allowTouchMove: true,
            mousewheel: true,
            loop: true,
            /*autoplay: {
                delay: 2000,
            },*/
            navigation: {
                nextEl: '.swiper-next',
                prevEl: '.swiper-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + '</span>';
                },
            },

            effect: 'fade',
            fadeEffect: {
                crossFade: false,
            },
            watchSlidesProgress: true,
            speed: 300,
            on: {
                init: function () {

                },
                afterInit: function (){
                    const activeSlide = document.querySelector('.swiper-slide-active');
                    if(activeSlide){
                        updateLegend(activeSlide);
                    }
                },
                slideChangeTransitionStart: function (){
                    const activeSlide = document.querySelector('.swiper-slide-active');
                    if(activeSlide){
                        updateLegend(activeSlide);
                    }
                }
            }
        });
    }

    function updateLegend(activeSlide) {
        const slideLegend = document.querySelector('.slide-legend');
        const slideIndex = document.querySelector('.slide-index .index');

        const legend = activeSlide.dataset.caption;
        var index = activeSlide.dataset.index;
        const url = activeSlide.dataset.url;

        if( index > 0 && index <= 9){
            index = "0"+index;
        }

        slideLegend.innerHTML = "<a href='"+url+"'>"+legend+"</a>";
        slideIndex.innerHTML = index;
    }
}


Carousel();


