const filters = document.querySelectorAll('.filters a');


filters.forEach((filter) => {
    filter.addEventListener('click', (e) => {
        e.preventDefault();
        const filtered = [];

        if (filter.dataset.target === 'all') {
            filters.forEach((filter) => {
                filter.classList.remove('selected');
            });
            filter.classList.add('selected');
            filtered.push(filter.dataset.target);
            updateCurrent(filtered);
            filterProjects(filtered);
        } else {
            filter.classList.toggle('selected');
            filters.forEach((filter) => {
                if (filter.dataset.target === 'all') {
                    filter.classList.remove('selected');
                }
            });

            const selected = document.querySelectorAll('a.selected');
            selected.forEach((el) => {
                filtered.push(el.dataset.target);
            });
            updateCurrent(filtered);
            filterProjects(filtered);

            if (filtered.length === 0) {
                const all = document.querySelector('.filters a[data-target="all"]');
                all.classList.add('selected');
                filtered.push(all.dataset.target);
                updateCurrent(filtered);
                filterProjects(filtered);
            }
        }
    });
});


const showSelectedItems = (filtered) => {

}

const updateCurrent = (arr) => {
    const current = document.querySelector('.current');
    var currentString = [];

    arr.forEach((item) => {
        const targetText = document.querySelector('.filters a[data-target="' + item + '"]').innerHTML;
        currentString.push(targetText);
    });


    var textToAdd = currentString.slice(0, 2).toString().replace(',', ' ');
    current.innerHTML = textToAdd;
    console.log(currentString);

    if (currentString.length > 2) {
        const arrLength = currentString.length;
        const sliceAt = 2;

        const moreIndex = arrLength - sliceAt;

        textToAdd = currentString.slice(0, 2).toString().replace(',', ' ') + "  +" + moreIndex;
        current.innerHTML = textToAdd;
    }
}

const filterProjects = (arr) => {
    const items = document.querySelectorAll('.project-item');

    console.log(arr);

    if (arr.includes('all')) {
        items.forEach((item) => {
            item.style.display = null;
        });
    } else {
        items.forEach((item) => {
            var hasAllClasses = arr.some(function (className) {
                return item.classList.contains(className);
            });
            if (hasAllClasses === false) {
                item.style.display = 'none';
            } else {
                item.style.display = null;
            }
        });
    }
}