/*

const scrollToSmoothSettings = {
    // Selectors
    container: ".content-wrapper",
    targetAttribute: 'data-scroll',
    topOnEmptyHash: true,
    offset: 100,
    // Speed and duration
    duration: 300,
    durationRelative: true,
    durationMin: 200,
    durationMax: 400,
    easing: easeOutCubic,
    // Callbacks
    onScrollUpdate: null,
    onScrollEnd: null
};

const smoothScroll = new scrollToSmooth('.sub-menu a', scrollToSmoothSettings);
smoothScroll.init();

const anchors = document.querySelectorAll('a[data-scroll]');
const sections = document.querySelectorAll('section.is-anchor');


anchors.forEach((anchor) =>{
    anchor.addEventListener('click', function (e){
        e.preventDefault();
        anchors.forEach((anchor) =>{
            anchor.classList.remove('active');
        });
        anchor.classList.add('active');
    });
});



const scrollContainer = document.querySelector('.content-wrapper');


scrollContainer.addEventListener('scroll', () => {

    var scrollTop = document.querySelector('.content-wrapper').scrollTop;

    sections.forEach((section) => {
        const sectionId = "#"+section.getAttribute('id');
        const anchor = document.querySelector('.sub-menu a[data-scroll="'+sectionId+'"]');

        console.log(sectionId);

        var elementOffset = section.offsetTop,
            distance = (elementOffset - scrollTop);

        console.log(distance);

        if (distance <= 100) {
            anchors.forEach((anchor) => {
                anchor.classList.remove('active');
            });
            anchor.classList.add('active');
        } else {

        }

    });

});
*/
const content = document.querySelector('.content-wrapper');
const anchors = document.querySelectorAll('.sub-menu.anchors .anchor');
var scrollTop;

if(window.location.hash) {
    const section = document.querySelector(window.location.hash);
    const anchor = document.querySelector('.sub-menu.anchors .anchor[data-scroll="'+window.location.hash+'"]');
    anchors.forEach((anchor) =>{
       anchor.classList.remove('active');
    });
    anchor.classList.add('active');
    if(window.innerWidth > 768){
        var headerHeight = document.querySelector('.sub-menu').clientHeight;
        scrollTop = section.offsetTop - headerHeight;
    }else{
        scrollTop = section.offsetTop;
    }
    setTimeout(function (){
        content.scroll({
            behavior: 'smooth',
            left: 0,
            top: scrollTop,
        });
    }, 200);

}

anchors.forEach((anchor) =>{
    anchor.addEventListener('click', function (){
        const section = document.querySelector(anchor.dataset.scroll);
        if(window.innerWidth > 768){
            var headerHeight = document.querySelector('.sub-menu').clientHeight;
            scrollTop = section.offsetTop - headerHeight;
        }else{
            scrollTop = section.offsetTop;
        }
        setTimeout(function (){
            content.scroll({
                behavior: 'smooth',
                left: 0,
                top: scrollTop,
            });
        }, 200);
    });

});

const scrollContainer = document.querySelector('.content-wrapper');
const sections = document.querySelectorAll('.scroll-section');

var activeMenuBefore = document.querySelector('.sub-menu.anchors a.active');

const menuItemAnchors = document.querySelectorAll(".menu-item .content .anchor");

menuItemAnchors.forEach((anchor) =>{

   anchor.addEventListener('click', function (){
       if(document.body.dataset.template == "about"){
           const menuContent = document.querySelector(".menu.content");
           menuContent.style.maxHeight = null;
       }else{
           return false;
       }
   });

});



scrollContainer?.addEventListener('scroll', () => {

    var scrollTop = document.querySelector('.content-wrapper').scrollTop;

    sections.forEach((section) => {
        const sectionId = "#" + section.getAttribute('id');
        const anchor = document.querySelector('.sub-menu a[data-scroll="' + sectionId + '"]');

        var elementOffset = section.offsetTop,
            distance = (elementOffset - scrollTop);

        if (distance <= 100) {
            anchors.forEach((anchor) => {
                anchor.classList.remove('active');
            });
            anchor.classList.add('active');
        } else {

        }

    });

    const imageSection = document.querySelector('#section-5');
    const anchor5 = document.querySelector('.sub-menu a[data-scroll="#section-5"]');
    var section5offSet = imageSection?.offsetTop,
        distance5 = (section5offSet - scrollTop);

    if (distance5 <= 100) {
        anchors.forEach((anchor) => {
            anchor.classList.remove('active');
        });
        anchor5?.classList.add('active');
    }else{
        anchor5?.classList.remove('active');
    }


    var activeMenuAfter = document.querySelector('.sub-menu.anchors a.active');

    if(activeMenuAfter !== activeMenuBefore){
        activeMenuBefore = activeMenuAfter;
        const activeSection = document.querySelector(activeMenuAfter.dataset.scroll);
        if(activeSection.dataset.cover){
            changeImage(activeSection);
        }

    }


});



function changeImage(activeSection){
    const imgContent = activeSection.dataset.cover;

    const imgContainer = document.querySelector('.section-image-container div');

    imgContainer.innerHTML = imgContent;

}

