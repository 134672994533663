__webpack_public_path__ = "http://dev.local:8080/assets/";

import "../scss/application.scss";
import { onLoading } from "./_modules/website";
import { loadPage } from "./_modules/home";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';



import './_modules/home';
import './_modules/swiper';
import './_modules/about';

import './_modules/collapse';
import './_modules/filters';


document.addEventListener("DOMContentLoaded", () => {
  onLoading();
  loadPage();


  if(sessionStorage['overlay'] === undefined){
    const overlay = document.querySelector('.loading-overlay');
    overlay?.classList.add('hide');
    sessionStorage['overlay'] = false;
  }else{
    const overlay = document.querySelector('.loading-overlay');
    overlay?.remove();
  }

  var readValue = sessionStorage['overlay'];
  console.log(readValue);

});



