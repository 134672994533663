export const loadPage = () => {
    document.body.classList.add("loaded");
    console.log("Loaded");
};


if(document.body.getAttribute('id') === "home"){


const catLinks = document.querySelectorAll('.category-menu .menu-item a');
const catDisplay = document.querySelector('.categories-display');
const header = document.querySelector('header');
const mainMenu = document.querySelector('.main-menu');
const catWrap = document.querySelectorAll('.category-wrap');
const imageContainer = document.querySelector('.category-cover');

const projectLink = document.querySelectorAll('.category-wrap-inner a');

catLinks.forEach((link) => {
    link.addEventListener('mouseenter', () => {
        catDisplay.classList.add('show');
        const target = document.querySelector('.' + link.dataset.target);
        catWrap.forEach((wrap) => {
            wrap.classList.remove('show');
            wrap.children[0].scrollTop = 0;
        });
        target.classList.add('show');
        setFirstChildActive(target.children[0]);
    });
});

imageContainer.addEventListener("mouseenter", () =>{
  catDisplay.classList.remove('show');
  catWrap.forEach((wrap) =>{
    wrap.classList.remove('show');
    wrap.children[0].scrollTop = 0;
  });
});

mainMenu.addEventListener("mouseenter", () => {
    catDisplay.classList.remove('show');
    catWrap.forEach((wrap) => {
        wrap.classList.remove('show');
        wrap.children[0].scrollTop = 0;
    });
});



projectLink.forEach((link) => {
    link.addEventListener('mouseenter', () => {
        projectLink.forEach((link) => {
            link.classList.remove('active');
        });
        link.classList.add('active');
        const linkImage = link.dataset.image;
        if (imageContainer.dataset.id !== link.dataset.id) {
            imageContainer.innerHTML = linkImage;
            imageContainer.dataset.id = link.dataset.id;
        }

    });
});


const setFirstChildActive = (parent) => {

    projectLink.forEach((link) => {
        link.classList.remove('active');
    });

    const firstLink = parent.querySelector('.cat-project-item:first-of-type a');

    if(firstLink){
        firstLink.classList.add('active');

        const firstLinkCover = firstLink.dataset.image;
        const projectID = firstLink.dataset.id;

        imageContainer.innerHTML = firstLinkCover;
        imageContainer.dataset.id = projectID;
    }



};


}
